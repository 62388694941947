html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
